import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import './Popup.css'; // Import CSS for styling

const Popup = ({ onClose }) => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs.send('service_f5eftdm', 'template_p0me1y9', { email }, 'HtFYuzNDpkdR5DbVT')
      .then(response => {
        console.log('Email sent successfully:', response);
        onClose(); // Close the popup after successful submission
      })
      .catch(error => {
        console.error('Error sending email:', error);
        // Handle errors here
      });
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Subscribe to get all the styles!</h2>
        
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
            required
          />
          <button type="submit">Subscribe</button>
        </form>
        <button onClick={onClose} className="popup-close">X</button>
      </div>
    </div>
  );
};

export default Popup;

