import React, { useState, useEffect } from 'react';
import Popup from './Popup'; // Import the Popup component
import ImageUpload from './components/ImageUpload';
import FaceSwapping from './components/FaceSwapping';
import './App.css'; // Import CSS for styling
import logoe from '../src/images/HAIRStudio.png';

const App = () => {
  const [userImageFile, setUserImageFile] = useState(null);
  const [targetImageUrl, setTargetImageUrl] = useState(null);
  const [visibleRows, setVisibleRows] = useState(2);
  const [expanded, setExpanded] = useState(false); // New state for tracking expanded rows
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State to control popup visibility

  useEffect(() => {
    // Initialize Google Analytics
    window.gtag('config', 'G-155JH3R3CR', {
      page_path: window.location.pathname,
    });

    // Show popup after 30 seconds
    //const timer = setTimeout(() => {
    //  setIsPopupVisible(true);
    //}, 45000); // 30000 milliseconds = 15 seconds

    // Cleanup timer on component unmount
    //return () => clearTimeout(timer);
  }, []);

  const handleUserImageFileSelect = (file) => {
    setUserImageFile(file);
    window.gtag('event', 'upload', {
      event_category: 'User Interaction',
      event_label: 'User uploaded a face image',
    });
  };

  const handleTargetImageSelect = (url) => {
    setTargetImageUrl(url);
    window.gtag('event', 'select', {
      event_category: 'User Interaction',
      event_label: `User selected target image ${url}`,
    });
  };

  const targetImages = [
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8986.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8985.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8984.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8982.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8981.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8980.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8963.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8966.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8965.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8925.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8926.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8927.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8928.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8929.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8931.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8933.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8937.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8939.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8942.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8943.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8945.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8946.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8947.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8872.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8877.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8878.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8881.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8882.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8883.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8884.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8885.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8887.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8889.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8892.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8893.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8852.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8853.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8854.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8855.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8857.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8858.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8859.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8861.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8863.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8864.jpeg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8825.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8826.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8828.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8829.JPG',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8830.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8831.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8832.JPG',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8835.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8836.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8837.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/IMG_8839.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/61a%2Bf-sRlvL._AC_SX679_%20(1).jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/61if4BQuhhL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/715YGD9arpL._AC_SY879_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/719uCr4DvCL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71YdvaTRcnL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71iH741gUzL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71yM7jgmXGL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71ytacO1PUL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/8166aTJP28L._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81EtA84eADL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81G4EddFG9L._AC_SY879_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81Otp%2BS78WL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81mN8rrnSDL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81pK2fhPU1L._AC_SY879_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81sr0zNYh1L._AC_SX679_%20(1).jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/16d447bacd88b693ae85a8ba037acca1.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/61c4bQIKPCL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/61mJ-LMhx-L._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/7157YzI1TLL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71EFH3WS%2BaL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71H1tbhfsTL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/71qZgmXdMaL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81GDOaE6RaL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81ZBDdhcV%2BL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/81jYVAmLafL._AC_SX679_.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/caa21458e3839ccc5de7389db5f30eea.jpg',
    'https://raw.githubusercontent.com/dzina080/dzina080.github.io/main/targets/jaliza%2024_%20Boho%20Locs%20Wig%20With%20Human%20Kinky%20Baby%20Hair.jpg'
  ];

  const imagesPerRow = 12;
  const rowsToShow = Math.ceil(targetImages.length / imagesPerRow);

  const handleShowToggle = () => {
    if (expanded) {
      setVisibleRows(1);
      setExpanded(false);
    } else {
      setVisibleRows(rowsToShow);
      setExpanded(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="app-container">
      <img src={logoe} style={{ maxWidth: '40%', marginLeft: '0%', maxHeight: '40%', marginTop: '0px' }} />
      <div className="upload-container">
        <div className="upload-section">
          <label>
            <span className="upload-label">Upload a face Image:</span>
            <ImageUpload onFileSelect={handleUserImageFileSelect} />
          </label>
        </div>
        <div className="upload-section">
          <span className="upload-label">Select your style:</span>
          <div className="target-images">
            {targetImages.slice(0, visibleRows * imagesPerRow).map((url, index) => (
              <div
                key={index}
                className={`target-image ${url === targetImageUrl ? 'selected' : ''}`}
                onClick={() => handleTargetImageSelect(url)}
              >
                <img src={url} alt={`Target ${index + 1}`} />
              </div>
            ))}
          </div>
          <button className="dropdown-button" onClick={handleShowToggle}>
            {expanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
      <FaceSwapping userImageFile={userImageFile} targetImageUrl={targetImageUrl} />
      
      {isPopupVisible && <Popup onClose={handleClosePopup} />}
    </div>
  );
};

export default App;
